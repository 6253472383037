import { SubjectID } from '../../../common/clients/api/types.gen';
import { Route } from './Route';

// FIXME: PB-6225 - Remove 'Route.BrightPodcast' after the migration
export const SubjectByRoute: Record<Route, SubjectID | undefined> = {
    [Route.AdsTxt]: undefined,
    [Route.Browserconfig]: undefined,
    [Route.AMP]: SubjectID.MOTORSPORT,
    [Route.Article]: undefined,
    [Route.ArticlePreview]: undefined,
    [Route.AttemptRefresh]: undefined,
    [Route.Betting]: undefined,
    [Route.BrandOverview]: undefined,
    [Route.Brand]: undefined,
    [Route.BrightPodcast]: undefined,
    [Route.CarOverview]: undefined,
    [Route.Car]: undefined,
    [Route.CategoryNews]: undefined,
    [Route.CircuitOverview]: SubjectID.MOTORSPORT,
    [Route.Circuit]: SubjectID.MOTORSPORT,
    [Route.ClubOverview]: SubjectID.FOOTBALL,
    [Route.Club]: SubjectID.FOOTBALL,
    [Route.Comments]: undefined,
    [Route.CompetitionAssists]: SubjectID.FOOTBALL,
    [Route.CompetitionCards]: SubjectID.FOOTBALL,
    [Route.CompetitionOverview]: SubjectID.FOOTBALL,
    [Route.CompetitionTopscorers]: SubjectID.FOOTBALL,
    [Route.Competition]: SubjectID.FOOTBALL,
    [Route.Contact]: undefined,
    [Route.DossierOverview]: undefined,
    [Route.Dossier]: undefined,
    [Route.DriverOverview]: SubjectID.MOTORSPORT,
    [Route.Driver]: SubjectID.MOTORSPORT,
    [Route.Error]: undefined,
    [Route.F1Calendar]: SubjectID.MOTORSPORT,
    [Route.ForgotPassword]: undefined,
    [Route.GoogleNewsSitemap]: undefined,
    [Route.GrandsprixOverview]: SubjectID.MOTORSPORT,
    [Route.Grandsprix]: SubjectID.MOTORSPORT,
    [Route.Homepage]: undefined,
    [Route.Liveness]: undefined,
    [Route.LocationOverview]: undefined,
    [Route.Location]: undefined,
    [Route.Login]: undefined,
    [Route.Manifest]: undefined,
    [Route.MatchByID]: SubjectID.FOOTBALL,
    [Route.MatchOverviewDay]: SubjectID.FOOTBALL,
    [Route.MatchOverview]: SubjectID.FOOTBALL,
    [Route.Match]: SubjectID.FOOTBALL,
    [Route.MyFormation]: SubjectID.FOOTBALL,
    [Route.MySubscription]: undefined,
    [Route.NavigationSitemap]: undefined,
    [Route.Notifications]: undefined,
    [Route.OneSignalSDKWorker]: undefined,
    [Route.OrganizationOverview]: undefined,
    [Route.Organization]: undefined,
    [Route.Page]: undefined,
    [Route.PasswordReset]: undefined,
    [Route.PersonOverview]: undefined,
    [Route.Person]: undefined,
    [Route.PlayerOverview]: SubjectID.FOOTBALL,
    [Route.Player]: SubjectID.FOOTBALL,
    [Route.Premium]: undefined,
    [Route.Preview]: undefined,
    [Route.Profile]: undefined,
    [Route.Podcast]: undefined,
    [Route.RaceClassOverview]: SubjectID.MOTORSPORT,
    [Route.RaceClass]: SubjectID.MOTORSPORT,
    [Route.Register]: undefined,
    [Route.Robots]: undefined,
    [Route.RssMedia]: undefined,
    [Route.RssOverview]: undefined,
    [Route.RssWildcard]: undefined,
    [Route.Rss]: undefined,
    [Route.Sandbox]: undefined,
    [Route.Search]: undefined,
    [Route.SegmentedNewsSitemap]: undefined,
    [Route.SegmentedTagSitemap]: undefined,
    [Route.SegmentedVideosSitemap]: undefined,
    [Route.Settings]: undefined,
    [Route.Signin]: undefined,
    [Route.SitemapIndex]: undefined,
    [Route.Subscribe]: undefined,
    [Route.SubscribeSwitch]: undefined,
    [Route.SubscribeSuccess]: undefined,
    [Route.SubscribeFailure]: undefined,
    [Route.Standing]: SubjectID.MOTORSPORT,
    [Route.TagSitemap]: undefined,
    [Route.TeamBossOverview]: SubjectID.MOTORSPORT,
    [Route.TeamBoss]: SubjectID.MOTORSPORT,
    [Route.TeamOverview]: SubjectID.MOTORSPORT,
    [Route.Team]: SubjectID.MOTORSPORT,
    [Route.TopicOverview]: undefined,
    [Route.Topic]: undefined,
    [Route.TransferNews]: SubjectID.FOOTBALL,
    [Route.TransferRumors]: SubjectID.FOOTBALL,
    [Route.User]: undefined,
    [Route.VerifyEmail]: undefined,
    [Route.VideoArticle]: undefined,
    [Route.VideoArticlePreview]: undefined,
    [Route.VideoCategory]: undefined,
    [Route.VideoOverview]: undefined,
    [Route.WellKnownAndroid]: undefined,
    [Route.WellKnownIOS]: undefined,
};
