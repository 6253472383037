import { useMemo, useState } from 'react';

import { useTranslation } from '@cms/i18n/client';
import { ModuleScheduleItem, News, Pagination, Tag } from '@common/clients/api';
import { DefaultServerSideProps } from '@common/types/DefaultProps';
import { fetchNews } from '@web/handlers/fetchNews';
import { Ad, AdPlacement } from '@web/molecules/Ad';
import { NewsList } from '@web/molecules/NewsList';
import { NotificationBarHolder } from '@web/molecules/NotificationBar';
import { TagIntroBlock, TagIntroBlockProps, TagIntroBlockType } from '@web/molecules/TagIntroBlock';
import { TagOutroBlock } from '@web/molecules/TagOutroBlock/TagOutroBlock';
import { MetaHead } from '@web/organisms/MetaHead';
import { Sidebar } from '@web/organisms/Sidebar';
import { ParentByTagRoute, Route, RouteByTagType, SubjectByRoute } from '@web/routing';

export interface State {
    currentPage: number;
    pagination?: Pagination;
    newsItems: News[];
    tag?: Tag;
    promoItems?: ModuleScheduleItem[];
    search?: string;
    checkNextPage?: boolean;
}

export interface Props {
    state: State;
}

export const TagDetail = ({ contextData, state: initialState }: Props & DefaultServerSideProps) => {
    const __meta = useTranslation('meta').t;

    const [state, setState] = useState<State>(initialState);

    const { tag, newsItems } = state;

    const onReadMoreClick = async (): Promise<void> => {
        const newState: State = {
            ...state,
            currentPage: state.currentPage + 1,
        };

        const { data, pagination } = await fetchNews({
            contextData,
            currentPage: newState.currentPage,
            isClientSide: true,
            tagID: state.tag?.tagID,
        });

        data?.forEach((item: News) => {
            state.newsItems?.push(item);
        });

        newState.newsItems = state.newsItems;
        newState.pagination = pagination;

        setState(newState);
    };

    const tagIntroBlockData = useMemo<TagIntroBlockProps>(
        () => ({
            type: TagIntroBlockType.COMMON,
            superTitle: tag?.tagTypeSlug ? __meta(`title.${tag?.tagTypeSlug}`) : '',
            title: tag?.title || '',
            description: tag?.tagIntro || tag?.tagMetaDescription || '',
            thumbnail: tag?.thumbnail || '',
            backgroundImage: tag?.background || '',
            hasTagOutro: Boolean(tag?.tagIntro) && Boolean(tag?.tagDescription),
        }),
        [__meta, tag],
    );

    const noIndex = useMemo(
        () =>
            (!tag?.showArticle && !tag?.showFooter && !tag?.showHome && !tag?.showOverview) ||
            newsItems.length <= 1,
        [tag, newsItems],
    );

    const tagMetaTitle: string = tag?.tagMetaTitle || tag?.title || '';
    const route: Route | undefined = tag?.tagType ? RouteByTagType[tag?.tagType] : undefined;
    const parent: Route | undefined = route !== undefined ? ParentByTagRoute[route] : undefined;
    const parents: Route[] =
        parent &&
        // Exclude club overview etc on non-football platforms
        (contextData.platform.subject === SubjectByRoute[parent] || SubjectByRoute[parent] === undefined)
            ? [parent]
            : [];

    return (
        <>
            <MetaHead
                title={tagMetaTitle}
                description={tag?.tagMetaDescription || __meta(`tag-news.description`, { tag: tag?.title })}
                image={tag?.thumbnail}
                parents={parents}
                noIndex={noIndex}
            />
            <div className="main-content">
                <TagIntroBlock {...tagIntroBlockData} __translate={__meta} />
                <Ad placement={AdPlacement.NON_HOME_AFTER_SECTION_1} />
                <NewsList
                    items={newsItems}
                    pagination={state.pagination}
                    tag={tag}
                    onReadMoreClick={onReadMoreClick}
                    showIconTabs={true}
                    promoItems={state.promoItems}
                />
                <Ad placement={AdPlacement.NON_HOME_AFTER_SECTION_2} />
                {tagIntroBlockData?.hasTagOutro ? (
                    <TagOutroBlock tagOutro={tag?.tagDescription || ''} />
                ) : null}
            </div>
            <Sidebar />
            <NotificationBarHolder />
        </>
    );
};
